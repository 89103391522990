mcWeb.payments = new function () { };

mcWeb.payments.events = {
    onShowUpdateIcon: "mcWeb.payments.onShowUpdateIcon",
    onHideUpdateIcon: "mcWeb.payments.onHideUpdateIcon",
    reloadPayments: "mcWeb.payments.reloadPayments",
    focusFirstName: "mcWeb.payments.paymentsfocusFirstName",
    updateOrgNr: "mcWeb.payments.updateOrgNr",
    processPaymentMethods: "mcWeb.payments.processPaymentMethods",
    onSelectedPayment: "mcWeb.payments.onSelectedPayment",
    updateKco: "mcWeb.payments.events.updateKco"
};