mcWeb.cart = new function () { };

mcWeb.cart.events = {
    hasCartItems: "mcWeb.cart.events.hasCartItems",
    onChangedCart: "mcWeb.cart.events.onChangedCart",
    onChangedQuantityCart: "mcWeb.cart.events.onChangedQuantityCart",
    focusDiscoutCode: "mcWeb.cart.events.focusDiscoutCode",
    cartGuid: "mcWeb.cart.events.cartGuid",
    onChangeCollectedDelivery: "mcWeb.cart.events.onChangeCollectedDelivery",
    onShowNewItemInCart: "mcWeb.cart.events.onShowNewItemInCart",
    onShowNewItemInCartText: "mcWeb.cart.events.onShowNewItemInCartText",
    onHideNewItemInCart: "mcWeb.cart.events.onHideNewItemInCart",
    onRequestSendt: "mcWeb.cart.events.onRequestSendt",
    onPopupForProductAssembly: "mcWeb.cart.events.onPopupForProductAssembly"
};