mcWeb.cartsmall = new function () { };

mcWeb.cartsmall.events = {
    onChangedQuantityCartSmall: "mcWeb.cartsmall.onChangedQuantityCartSmall",
    onUpdateCart: "mcWeb.cartsmall.events.onUpdateCart",
    onUpdateCartForceLoad: "mcWeb.cartsmall.events.onUpdateCartForceLoad",
    onChangeVat: "mcWeb.cartsmall.events.onChangeVat",
    onShowNewItemInCart: "mcWeb.cartsmall.events.onShowNewItemInCart",
    onShowNewItemInCartText: "mcWeb.cartsmall.events.onShowNewItemInCartText",
    onHideNewItemInCart: "mcWeb.cartsmall.events.onHideNewItemInCart",
    onChangeFav: "mcWeb.cartsmall.events.onChangeFav",
    onCartItemRemoved: "mcWeb.cartsmall.events.onCartItemRemoved",
    onGotoSelectedCart: "mcWeb.cartsmall.events.onGotoSelectedCart",
    onIsCartChanged: "mcWeb.cartsmall.events.onIsCartChanged"
};