mcWeb.receipt = new function () {

    this.events = {
    }

    this.vm = function (id) {
        var $ = jQuery;
        var self = this;
        this.id = "#" + id;
        this.langService = new LanguageService();
        this.expressCustomer = ko.observable(false);
        this.showCheckoutConfirmation = ko.observable(false);
        this.showReceiptInformation = ko.observable(true);

        this.showSantanderPayeverApproved = ko.observable(false);
        this.showSantanderPayeverManual = ko.observable(false);
        this.showNordeaFinancingResult = ko.observable(false);
        this.nordeaFinancingText = ko.observable('');

        this.load = function (data, settings) {
            if (data.ExpressCustomer)
                self.expressCustomer(true);

            self.showCheckoutConfirmation(false);
            self.GetCheckoutConfirmationSnippet();

            self.showSantanderPayeverApproved(false);
            self.showSantanderPayeverManual(false);
            self.GetSantanderPayeverApplicationResult();
            self.GetLatestNordeaFinancingApplicationResult();
        };

        this.GetSantanderPayeverApplicationResult = function () {
            var serviceLocal = new WebApiService("Payment");
            serviceLocal.GetAction("GetSantanderPayeverApplicationResult", null).then(function (result) {
                if (result == null || result.length < 1) {
                    return;
                }

                var currentPaymentMethod = result.CurrentPaymentMethod;
                if (currentPaymentMethod !== "SantanderPayever") return;

                var json = JSON.parse(result.ExtraInfoJson);

                if (json.Status == "APPROVED") {
                    self.showSantanderPayeverApproved(true);
                    self.showReceiptInformation(false);
                } else if (json.Status == "MANUAL") {
                    self.showSantanderPayeverManual(true);
                    self.showReceiptInformation(false);
                }
            });
        }

        this.GetLatestNordeaFinancingApplicationResult = function () {
            var serviceLocal = new WebApiService("Payment");
            serviceLocal.GetAction("GetLatestNordeaFinancingApplicationResult", null).then(function (result) {
                if (result == null || result.length < 1) {
                    return;
                }

                var currentPaymentMethod = result.CurrentPaymentMethod;
                if (currentPaymentMethod !== "NordeaFinancing") return;

                if (result.Msg !== null && result.Msg.length > 0) {
                    self.showNordeaFinancingResult(true);
                    self.nordeaFinancingText(result.Msg);
                    self.showReceiptInformation(false);
                }
                else {
                    self.showNordeaFinancingResult(false);
                    self.showReceiptInformation(true);
                }
            });
        };


        this.GetCheckoutConfirmationSnippet = function() {
            var serviceLocal = new WebApiService("Payment");
            serviceLocal.GetAction("GetCheckoutConfirmationSnippet", null).then(function(result) {
                if (result === null || result.length < 1 || result.Snippet.length === 0) {
                    return;
                }

                if (result.UseSoftlog) {
                    if (result.CustomerEmail !== mcWeb.currentUser.getUser())
                        mcWeb.currentUser.setUser(result.CustomerEmail);
                }

                self.showCheckoutConfirmation(true);
                $("#klarna-confirmation-snippet").html(result.Snippet);
            });
        };

        this.OnClickSendOrder = function () {
            var btnOrderEl = $("#McPaymentAddOrderbottom");
            var serviceLocal = new WebApiService("Payment");

            btnOrderEl.removeClass("btn-primary");
            btnOrderEl.addClass("btn-default disabled");

            serviceLocal.GetAction("GetConfirmationUrl", null).then(function (result) {
                if (result === null || result.length < 1) {
                    console.log("Error parsing confirmation url");

                    btnOrderEl.removeClass("btn-default disabled");
                    btnOrderEl.addClass("btn-primary");

                    return;
                }

                window.location.href = result;
            });
        }
    };
};
